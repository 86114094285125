import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FAQModel } from 'src/app/core/domain/faq.model';
import { LandingEventsService } from '../../../../services/landing-events.service';

@Component({
  selector: 'app-question-box',
  templateUrl: './question-box.component.html',
  styleUrls: ['./question-box.component.scss'],
  standalone: true,
  imports: [MatExpansionModule, NgClass, NgIf, MatIconModule, TranslateModule],
})
export class QuestionBoxComponent {
  @Input() question: FAQModel;

  panelOpenState = false;

  constructor(private _landingEventsService: LandingEventsService) {}

  onPanelHeaderClick(state: boolean) {
    this.panelOpenState = state;
    const { question } = this.question;
    if (this.panelOpenState) {
      this._landingEventsService.sendLandingMixpanelEvent('FAQ_question_expanded', {
        $expanded_question: question,
      });
      this._doLogGtagFAQExpanded(question);
    }
  }

  private async _doLogGtagFAQExpanded(question: string) {
    this._landingEventsService.sendGtagMixpanelEvent('faq_questions_expanded', {
      Question: question,
    });
  }
}
