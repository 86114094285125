<div class="faq">
  <div class="faq__header">
    <img
      [src]="assetsPath + 'arrow.svg'"
      alt="arrow pointing at frequently asked questions section title"
      class="faq__header__icon"
    />
    <p class="faq__header__title heading1--extra-bold">{{ 'LANDING.FAQ.HEADER' | translate }}</p>
  </div>
  <mat-accordion class="faq__accordion">
    <ng-container *ngFor="let question of questions">
      <app-question-box [question]="question"></app-question-box>
    </ng-container>
  </mat-accordion>
</div>
