import { NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { FAQModel } from 'src/app/core/domain/faq.model';
import { QuestionBoxComponent } from './question-box/question-box.component';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  standalone: true,
  imports: [MatExpansionModule, NgFor, QuestionBoxComponent, TranslateModule],
})
export class FaqComponent {
  assetsPath = 'assets/img/landing/faq/';

  @Input() questions: FAQModel[];
}
