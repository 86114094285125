<mat-expansion-panel
  class="question__expansion-panel"
  (opened)="onPanelHeaderClick(true)"
  (closed)="onPanelHeaderClick(false)"
  hideToggle="true"
  [ngClass]="{ 'question__expansion-panel--selected': panelOpenState }"
>
  <mat-expansion-panel-header class="question__expansion-panel__header">
    <mat-panel-title class="body1--bold question__expansion-panel__header__title">
      {{ question.question | translate }}
    </mat-panel-title>
    <mat-panel-description class="question__expansion-panel__header__description">
      <mat-icon *ngIf="!panelOpenState" class="question__expansion-panel__header__description__icon"
        >add</mat-icon
      >
      <mat-icon *ngIf="panelOpenState" class="question__expansion-panel__header__description__icon"
        >remove</mat-icon
      >
    </mat-panel-description>
  </mat-expansion-panel-header>
  <p
    class="body1--regular question__expansion-panel__body"
    [innerHTML]="question.answer | translate"
  ></p>
</mat-expansion-panel>
